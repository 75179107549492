import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogBody,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@lemonenergy/lemonpie-components'
import { ExternalLink } from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'

import useDialog from '~/hooks/useDialog'
import { analyticsEvents } from '~/res/constants/events'
import { trackEvent } from '~/utils/analytics/events'
import TextButton from '~/ui/TextButton'

const NonUserYetButton = ({ inverse }: { inverse?: boolean }): JSX.Element => {
  const [registerDialogOpen, openRegisterDialog, closeRegisterDialog] =
    useDialog()

  const handleClose = (e?: any) => {
    trackEvent({
      event: e,
      name: 'click_interaction',
      label: analyticsEvents.aquisition.close_dialog_without_navigate,
      interaction_type: 'button',
    })
    closeRegisterDialog()
  }

  const handleNavigate = (e: any) => {
    trackEvent({
      event: e,
      name: 'click_interaction',
      label: analyticsEvents.aquisition.go_to_aquisition,
      interaction_type: 'button',
    })
    closeRegisterDialog()
  }

  return (
    <>
      <TextButton
        inverted={inverse}
        onClick={(e) => {
          e.stopPropagation()
          openRegisterDialog()
          trackEvent({
            event: e,
            name: 'click_interaction',
            label: analyticsEvents.aquisition.open_aquisition_dialog,
            interaction_type: 'button',
          })
        }}
      >
        Você ainda não usa Lemon?
      </TextButton>

      <Dialog open={registerDialogOpen} onClose={handleClose}>
        <DialogBackdrop>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Faça seu cadastro pelo site</DialogTitle>
              <DialogCloseButton onClick={handleClose} />
            </DialogHeader>
            <DialogBody>
              <p>
                Você será direcionado para o site da Lemon para fazer um novo
                cadastro.
              </p>
            </DialogBody>
            <DialogFooter>
              <Button variant="primary" asChild onClick={handleNavigate}>
                <Link
                  to="https://energialemon.com.br/quero-economizar?&utm_source=others&utm_medium=aplicativo&utm_campaign=welcome_or_cpf_screen"
                  target="_blank"
                >
                  <ExternalLink className="mr-smaller" /> Continuar para o site
                </Link>
              </Button>
            </DialogFooter>
          </DialogContent>
        </DialogBackdrop>
      </Dialog>
    </>
  )
}
export default NonUserYetButton
